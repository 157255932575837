@import 'src/design-system/styles/layout-variables';
@import 'src/design-system/styles/typography.mixins';

.container {
  flex-grow: 1;
  display: grid;
  grid-template-rows: max-content auto;
  padding-top: $content-indent-top;

  & .headerWrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    padding: 0 $content-indent-right 0 $content-indent-left;

    & > button > span {
      margin: auto;
    }
  }

  & .title {
    margin: 0 0 16px 0;
    padding: 0;

    @include font-style-h4($font-weight: $font-weight-regular);
    font-family: inherit;
  }
}
